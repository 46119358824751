<script>
    import { createEventDispatcher, onMount } from "svelte";
    import('@mux/mux-player');
    

    /** @type {string}*/
    export let playback_id;
    export let thumbnail_time = 0;
    export let autoplay = false;
    export let loop = false;

    let className = '';
    export { className as class };

    let player;
    let ended = false;

    const dispatch = createEventDispatcher();

    onMount(() => {
        let observer;

        player.addEventListener('loadeddata', () => {
            if (!player) return;
            if (autoplay) {
                observer = new IntersectionObserver(
                    (entries) => {
                        let other_players = Array.from(
                            document.querySelectorAll('mux-player')
                        ).filter((p) => p !== player);

                        entries.forEach((entry) => {
                            if (entry.intersectionRatio < 1) {
                                player.pause();
                            } else {
                                //@ts-ignore
                                other_players.forEach((p) => p.pause());
                                player.play();
                            }
                        });
                    },
                    { threshold: 1 }
                );

                observer.observe(player);
            }
        });

        player.addEventListener('play', () => {
            if (!player) return;
            dispatch('play');
            document.querySelectorAll('mux-player').forEach((p) => {
                if (p !== player) {
                    //@ts-ignore
                    p.pause();
                }
            });
        });

        player.addEventListener('timeupdate', () => {
            if (!player) return;
            ended = player.currentTime >= player.duration;
        });

        player.addEventListener('pause', () => {
            if (!player) return;
            dispatch('pause');
        });

        player.addEventListener('ended', () => {
            if (!player) return;
            dispatch('ended');
        });

        return () => {
            if (observer) observer.disconnect();
        };
    });

</script>

<div class="w-full m-0 aspect-video relative bg-black overflow-hidden rounded-2xl {className}">
    <mux-player
        bind:this={player}
        disable-cookie="true"
        respect-do-not-track="true"
        disable-tracking="true"
        playback-type="on-demand"
        playback-id={playback_id}
        thumbnail-time={thumbnail_time}
        poster={autoplay
            ? 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='
            : undefined}
        class="w-full m-0 aspect-video"
        muted={autoplay ? true : undefined}
        loop={loop ? true : undefined}
    />

    {#if $$slots.end && ended}
        <div class="absolute inset-0 flex items-center justify-center">
            <slot name="end" />
        </div>
    {/if}
</div>
