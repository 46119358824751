<script>
    import Button from './Button.svelte';
    import Modal from './Modal.svelte';
    import Prose from './Prose.svelte';
    import { page } from '$app/stores';
    import { img_cdn } from './utils/image';
    import { logEvent } from './utils/logs';

    let className = '';
    export { className as class };

    let shown = false;

    const show = () => {
        shown = true;
        logEvent('money_back_show');
    };

    const hide = () => {
        shown = false;
        logEvent('money_back_hide');
    };
</script>

<button
    on:click={show}
    class="text-center block {className} border-4 border-transparent focus:bg-surface-muted hover:bg-surface-muted outline-none rounded-xl p-4 bg-surfaces-muted"
>
    <img
        src={img_cdn('shield.svg')}
        width="48"
        height="48"
        alt="Shield Icon"
        class="mx-auto mb-2"
    />
    <strong class="text-xl font-bold leading-none">Money Back <br> Guarantee</strong>
</button>

{#if shown}
    <Modal on:close={hide}>
        <img
            src={img_cdn('shield.svg')}
            width="120"
            height="120"
            alt="Shield Icon"
            class="mx-auto mb-2"
        />
        <Prose invert>
            {@html $page.data.money_back_explainer}
        </Prose>

        <svelte:fragment slot="footer">
            <Button outline block invert on:click={hide}>Close</Button
            >
        </svelte:fragment>
    </Modal>
{/if}
